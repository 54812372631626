import React from "react";

import { sansFam } from "../../utils/fonts";
import { rhythm, scale } from "../../utils/typography";

import Button from "../button";
import Input from "../input";
import Select from "../select";

const inputStyles = {
  width: `100%`,
  maxWidth: rhythm(12),
};

const ContentCTA = ({
  dripFormId,
  ctaHeadline,
  ctaBody,
  ctaButton,
  disciplines,
  dripTags,
  css,
  ...props
}) => (
  <form
    action={`https://www.getdrip.com/forms/${dripFormId}/submissions`}
    method="post"
    css={{
      color: `white`,
      fontFamily: sansFam,
      fontWeight: 400,
      ...css,
    }}
  >
    <h3 css={{ color: `white`, marginBottom: `1rem` }}>{ctaHeadline}</h3>
    <div
      css={{
        color: `rgba(255,255,255,.9)`,
        fontFamily: sansFam,
        fontWeight: 400,
        width: `100%`,
      }}
    >
      <p>{ctaBody}</p>
    </div>
    <div css={{ marginBottom: `1rem` }}>
      <Input
        type="email"
        name="fields[email]"
        placeholder="you@example.com"
        css={inputStyles}
      />
    </div>
    <div css={{ marginBottom: `1rem` }}>
      <Select name="fields[discipline]" css={inputStyles} required="required">
        <option value="">What best matches what you do?</option>
        {disciplines.map((discipline, index) => (
          <option value={discipline.slug} key={index}>
            {discipline.title}
          </option>
        ))}
      </Select>
      {dripTags.map((tag, index) => (
        <Input type="hidden" name="tags[]" value={tag} key={index} />
      ))}
    </div>
    <div css={{ marginBottom: `1rem` }}>
      <Button
        type="submit"
        name="submit"
        css={{
          background: `var(--highlightColor)`,
          color: `white`,
          fontWeight: 700,
        }}
      >
        {ctaButton}
      </Button>
    </div>
    <div
      css={{
        ...scale(-0.4),
        color: `rgba(255,255,255,.8)`,
      }}
    >
      <i>
        I seriously <b>hate</b> spam. Unsubscribe anytime.
      </i>
    </div>
  </form>
);

export default ContentCTA;
