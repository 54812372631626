import { graphql } from "gatsby";
import React from "react";
import get from "lodash/get";
import Helmet from "react-helmet";
import PropTypes from "prop-types";

import BaseLayout from "../components/base-layout";
import Container from "../components/container";
import Content from "../components/content";
import ContentCTA from "../components/content-cta";
import ContentAuthor from "../components/content-author";
import CenteredContentBox from "../components/centered-content-box";
import InvertedContentBox from "../components/inverted-content-box";
import Spacer from "../components/spacer";
import QuoteHero from "../components/quote-hero";
import { rhythm, scale } from "../utils/typography";
import presets from "../utils/presets";
import { getBodyStyle } from "../utils/styles";
import * as string from "../utils/string";

class HexfoxIndex extends React.Component {
  render() {
    const index = get(this, "props.data.markdownRemark");
    const [firstPara, restOfPost] = string.split(index.html, "</p>", 1);
    const disciplines = this.props.data.allDisciplinesJson.edges.map(
      item => item.node,
    );
    return (
      <BaseLayout>
        <Helmet defaultTitle="Hexfox | Automate Your Wasted Hours">
          <body
            css={{
              ...getBodyStyle(`index`),
            }}
          />
        </Helmet>
        <Spacer size={1} />
        <Container>
          <QuoteHero>
            “I waste countless hours every week doing monotonous, mind-numbing
            tasks that <i>I know</i> a computer could do...”
          </QuoteHero>
        </Container>
        <Spacer size={3} />
        <Container>
          <CenteredContentBox>
            <Spacer size={2} />
            <Content html={firstPara} css={{ "& > p": { ...scale(0.4) } }} />
            <ContentAuthor
              authorBio={
                index.frontmatter.authorBio || index.frontmatter.author.bio
              }
              avatarResolutions={
                index.frontmatter.author.avatar.children[0].fixed
              }
            />
            <Content html={restOfPost} />
            <Spacer size={2} />
          </CenteredContentBox>
          <Spacer size={3} />
          <InvertedContentBox
            className="cta"
            css={{
              "&&": {
                [presets.Tablet]: {
                  marginLeft: `auto`,
                  marginRight: `auto`,
                  maxWidth: rhythm(presets.contentWidth + 4),
                },
              },
            }}
          >
            <Spacer size={1} />
            <ContentCTA
              dripFormId="TODO"
              ctaHeadline="Sign Up TODO"
              ctaBody="Sign up"
              ctaButton="Sign up"
              disciplines={disciplines}
              dripTags={[`submitted:index`]}
            />
            <Spacer size={1} />
          </InvertedContentBox>
          <Spacer size={2} />
        </Container>
      </BaseLayout>
    );
  }
}

HexfoxIndex.propTypes = {
  route: PropTypes.object,
};

export default HexfoxIndex;

export const pageQuery = graphql`
  query HexfoxIndexQuery {
    markdownRemark(frontmatter: { slug: { eq: "index" } }) {
      id
      html
      frontmatter {
        author {
          first_name
          full_name
          bio
          avatar {
            children {
              ... on ImageSharp {
                fixed(width: 100, height: 100, quality: 75) {
                  base64
                  width
                  height
                  src
                  srcSet
                }
              }
            }
          }
        }
        authorBio
      }
    }
    allDisciplinesJson {
      edges {
        node {
          slug
          title
        }
      }
    }
  }
`;
