import React from "react";

import presets from "../../utils/presets";
import { rhythm } from "../../utils/typography";

const Input = ({ css, ...props }) => (
  <input
    css={{
      padding: `.5rem .5rem`,
      marginRight: rhythm(1 / 4),
      border: `none`,
      borderRadius: presets.radiusLg,
      ...css,
    }}
    {...props}
  />
);

export default Input;
