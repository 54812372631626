import React from "react";

import ContentBox from "../content-box";
import { rhythm } from "../../utils/typography";
import presets from "../../utils/presets";

const CenteredContentBox = ({ children, css, ...props }) => (
  <ContentBox
    css={{
      "&&": {
        [presets.Tablet]: {
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(presets.contentWidth + 4),
        },
      },
      ...css,
    }}
    {...props}
  >
    {children}
  </ContentBox>
);

export default CenteredContentBox;
