import React from "react";

import presets from "../../utils/presets";
import { rhythm } from "../../utils/typography";

const Select = ({ css, children, ...props }) => (
  <select
    css={{
      height: `2.5rem`,
      marginRight: rhythm(1 / 4),
      border: `none`,
      borderRadius: presets.radiusLg,
      ...css
    }}
    {...props}
  >
    {children}
  </select>
);

export default Select;
