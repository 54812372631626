import React from "react";

import presets from "../../utils/presets";
import { rhythm, scale } from "../../utils/typography";
import { serifFam } from "../../utils/fonts";

const QuoteHero = ({ subtitle, children, ...props }) => {
  return (
    <div
      css={{
        display: `flex`,
        alignItems: `center`,
        flexDirection: `column`,
        padding: rhythm(0.5),
      }}
    >
      <h1
        css={{
          ...scale(1.1),
          fontFamily: serifFam,
          maxWidth: rhythm(30),
          color: `white`,
          textAlign: `center`,
          [presets.Tablet]: {
            ...scale(1.6),
          },
        }}
      >
        {children}
      </h1>
      {subtitle && (
        <h2
          css={{
            fontWeight: `300`,
            color: `white`,
            marginTop: `1rem`,
            maxWidth: rhythm(20),
          }}
        >
          {subtitle}
        </h2>
      )}
    </div>
  );
};

export default QuoteHero;
