import React from "react";

import { rhythm } from "../../utils/typography";
import colors from "../../utils/colors";
import presets from "../../utils/presets";

const InvertedContentBox = ({ css, children, ...props }) => (
  <div
    css={{
      background: `transparent`,
      maxWidth: rhythm(presets.contentWidth + 4),
      padding: 0,
      color: colors.white,
      [presets.Tablet]: {
        borderLeft: `1px dashed rgba(239, 229, 229, 0.2)`,
        padding: `0 ${rhythm(2)}`,
      },
      ...css,
    }}
    {...props}
  >
    {children}
  </div>
);

export default InvertedContentBox;
