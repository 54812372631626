import React from "react";

import Img from "gatsby-image";
import { serifFam } from "../../utils/fonts";
import { rhythm, scale } from "../../utils/typography";

const ContentAuthor = ({ authorBio, avatarResolutions, css, ...props }) => {
  return (
    <div>
      <hr />
      <div
        css={{
          position: `relative`,
          display: `grid`,
          gridGap: rhythm(1),
          gridTemplateColumns: `minmax(50px, 100px) 1fr`,
          ...css,
        }}
      >
        <Img
          alt=""
          css={{
            borderRadius: `100%`,
          }}
          fixed={avatarResolutions}
        />
        <p
          css={{ ...scale(-0.2), fontStyle: `italic`, fontFamily: serifFam }}
          dangerouslySetInnerHTML={{ __html: authorBio }}
        />
      </div>
      <hr />
    </div>
  );
};
export default ContentAuthor;
