import React from "react";

import presets from "../../utils/presets";
import { rhythm } from "../../utils/typography";

const Button = ({ css, children, ...props }) => (
  <button
    css={{
      marginRight: rhythm(1 / 4),
      padding: `0 14px`,
      border: `none`,
      borderRadius: presets.radiusLg,
      cursor: `pointer`,
      whiteSpace: `nowrap`,
      display: `inline-block`,
      height: `40px`,
      lineHeight: `40px`,
      boxShadow: `0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)`,
      color: `var(--colorHighlight)`,
      textDecoration: `none`,
      transition: `all .15s ease`,
      ":hover": {
        transform: `translateY(-1px)`,
        boxShadow: `0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08)`,
      },
      ...css,
    }}
    {...props}
  >
    {children}
  </button>
);

export default Button;
