function split(str, separator, limit) {
  let newStr = str.split(separator);
  if (newStr.length > limit) {
    var ret = newStr.splice(0, limit);
    ret.push(newStr.join(separator));
    return ret;
  }
  return newStr;
}

/**
 * Split a string at the given index, returning a 2 string array.
 * @param {string} str - the string to split
 * @param {number} index - the index of the string to split at
 * @returns {string[]} a 2-item string array containing both segments
 */
function splitAt(str, index) {
  return [str.slice(0, index), str.slice(index)];
}

/**
 * Find the index of the n-th occurrence of a pattern within a string.
 * @param {string} str - the string to search
 * @param {string} pattern - the pattern to search for
 * @param {number} n - the nth occurrence of pattern to search for (1-indexed)
 * @returns {number} the index
 */
function nthIndex(str, pattern, n) {
  let len = str.length;
  let i = -1;
  while (n-- && i++ < len) {
    i = str.indexOf(pattern, i);
    if (i < 0) break;
  }
  return i;
}

/**
 * Split a string in half after the n-th occurence of a pattern.
 * @param {string} str - the string to split
 * @param {string} pattern - the pattern to split after
 * @param {number} occurence - the nth occurrence of pattern to split after (1-indexed)
 * @returns {string[]} the split string, made of two parts
 */
function partition(str, pattern, occurrence) {
  let occurenceIndex = nthIndex(str, pattern, occurrence);
  return splitAt(str, occurenceIndex + pattern.length);
}

export { split, splitAt, nthIndex, partition };
