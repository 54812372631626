import React from "react";

import { rhythm } from "../../utils/typography";
import colors from "../../utils/colors";
import presets from "../../utils/presets";

const ContentBox = ({ children, ...props }) => (
  <div
    css={{
      background: colors.white,
      borderTop: `5px solid var(--highlightColor)`,
      boxShadow: `0 5px 20px rgba(25, 17, 34, 0.1)`,
      transform: `translateZ(0)`,
      padding: `0 ${rhythm(1)} 0`,
      marginLeft: `-${rhythm(0.8)}`,
      width: `calc(100% + ${rhythm(1.6)})`,
      [presets.Tablet]: {
        width: `auto`,
        marginLeft: 0,
        borderRadius: presets.radiusLg,
        padding: `0 ${rhythm(2)}`,
      },
    }}
    {...props}
  >
    {children}
  </div>
);

export default ContentBox;
